<template>
    <div class="notification-wrapper">
        <div class="dismiss-notification-button" @click.stop="deleteNotification(notification.notification_id)">
            <img class="dismissIcon" :src='getImgUrl("Nav_decline.svg")'/>
        </div>
        <div
        v-if='notification.notification_meta !== null'
        class="notification-header" @click.stop="notificationClick(notification.notification_meta.tour_id)">
            <span class="notification-time">{{convertAPIDateTimeToString(notification.date_created)}}</span>
            <span class="id">{{notification.notification_id}}</span>
            <span class="title">
            {{title}}
            </span>
            <div class="text">
            {{text}}
            </div>
        </div>
        <div v-else class="notification-header" @click.stop="getDetails()">
            <span class="notification-time">{{convertAPIDateTimeToString(notification.date_created)}}</span>
            <span class="id">{{notification.notification_id}}</span>
            <span class="title">
                {{title}}
            </span>
            <div class="dialogue-wrapper" v-if='notification.previous !== undefined && !collapsed'>
                <div v-for='(reply, index) in notification.previous.slice().reverse()' :key='index' class="dialogue-text" :class="{'outgoing':isOutgoing(reply)}">
                    {{getValueForCurLanguage(reply.notification_text).value}}
                    <img v-if='reply.sent' class="sentIcon" :src='getImgUrl("Nav_check.svg")'/>
                </div>
                <div class="dialogue-text" :class="{'outgoing':isOutgoing(notification)}">{{text}}</div>
                <div v-if='notification.previous.length <= 0' class="noReplies">{{$t("notifications.noReplies")}}</div>
            </div>
            <div class="dialogue-wrapper" v-else>
                <div class="show-more">...</div>
                <div class="dialogue-text" :class="{'outgoing':isOutgoing(notification)}">{{text}}</div>
            </div>
            <div class="collapseIconWrap">
                <img class="collapseIcon" :class="{'expanded':!collapsed}" alt="" src="../assets/view-check/Nav_carrot.svg" />
            </div>
            <div v-if='isSupported("notificationReplies")' class="answer-wrapper">
                <textarea class="answer-textbox" @click.stop v-if="answering" type="text" placeholder=''  v-model='initialValues'/>
                <div v-if='!answering' class="answer-button" @click.stop="answer()">{{$t('notifications.answerbutton')}}</div>
                <div v-else class="send-button" @click.stop="sendReplie()">{{$t('notifications.sendbutton')}}</div>
            </div>
        </div>
        <confirmpopup :active='this.confirmActive' :msg='$t("messages.confirmStartTour")' :confirmed='this.confirm' :declined='this.decline'></confirmpopup>
    </div>
</template>


<script>

import dateFunctions from '@/mixins/dateFunctions';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: "Notification",
    data(){
        return{
            answering:false,
            values: this.initialValues,
            confirmActive: false,
            collapsed:true
        }
    },
    props:{
        notification: Object,
        default() {
            return {};
        },
        deleteFunction:Function
    },
    computed:{
        initialValues:{
            get () {
                let vals = ''
                return vals;
            },
            set(val){
                this.values = val;
            }
        },
        title:function(){
            let titleText = "";
            if(this.notification.notification_type === "new-comment"){
                return this.$t("general.newComment");
            }
            if(this.notification.notification_meta !== null){
                if(this.notification.notification_meta.task_name !== null && this.notification.notification_meta.task_name !== undefined){
                    return titleText = this.getValueForCurLanguage(this.notification.notification_meta.task_name).value
                }
            }
            if(this.notification.notification_title !== null && this.notification.notification_title !== undefined){
                titleText = this.getValueForCurLanguage(this.notification.notification_title).value
            }
            return titleText;
        },
        text:function(){
            let notificationText = "";
            if(this.notification.notification_meta !== null){
                if(this.notification.notification_meta.task_description !== null && this.notification.notification_meta.task_description !== undefined){
                    notificationText = this.getValueForCurLanguage(this.notification.notification_meta.task_description).value;
                }else if(this.notification.notification_meta.comment_text !== undefined && this.notification.notification_meta.comment_text !== null){
                    notificationText = this.getValueForCurLanguage(this.notification.notification_meta.comment_text).value;
                }
            }else{
                if(this.notification.notification_text !== null && this.notification.notification_text !== undefined){
                    notificationText = this.getValueForCurLanguage(this.notification.notification_text).value;
                }
            }
            return notificationText;
        },
        toLink:function(){
            if(this.notification.notification_type !== "new-task"){
                return "/docu/submission/" + this.notification.notification_meta.submission_id;
            }else{
                //if its a task root to the loadingscreen
                let taskId = this.notification.notification_meta.form_task_id;
                return "/check/task/" + taskId;
            }
        },
        ...mapGetters([
            'getValueForCurLanguage',
            'getFormIdByBaseId',
            'getRepliesByNotificationId'
        ]),
    },
    methods:{
        isOutgoing:function(notification){
            return notification.author_id === this.$store.state.user.user_id;
        },
        printToConsole(lable,notification){
            console.log(lable,notification)
        },
        getDetails(){
            this.$store.dispatch("getNotificationDetail", {'notificationId':this.notification.notification_id, 'authorId':this.notification.author_id}).then(res => {
                console.log("result: ",res);
            });
            this.collapsed = !this.collapsed;
        },
        answer(){
            this.answering = true;
        },
        deleteNotification(id){
            this.deleteFunction(id);
            //setTimeout(function () { this.resetHeight() }.bind(this), 2000);
        },
        isSupported(feature){
            return this.$store.getters.isFeatureSupportet(feature);
        },
        replacePlaceholders: function(text, notification){
            // /samplestring/g replaces all occurences of the string
            let res = text.replace(/%form_field_label%/g, this.getValueForCurLanguage(notification.notification_meta.form_field_label).value);
            res = res.replace(/%license_plate%/g, notification.notification_meta.license_plate);
            return res;
        },
        notificationClick(){
            //check if a tour is active
            if(this.$store.state.local.curCheck.chosenTruck >= 0 || this.notification.notification_meta.tour_id !== undefined || this.notification.notification_meta.form_task_id !== undefined){
                if(this.notification.notification_meta.tour_id !== undefined ){
                    this.$store.dispatch('getTour', this.notification.notification_meta.tour_id);
                }
                this.$store.commit('toggleNotifications');
                this.$store.dispatch('markNotificationAsSeen',this.notification.notification_id);
                this.$router.push(this.toLink);
            }else{
                this.confirmActive = true;
            }
        },
        confirm(){
            if (this.$route.path != '/check/lkw/') {
                this.$router.push('/check/lkw/')
            }
        },
        decline(){
            this.confirmActive = false;
        },
        sendReplie(){
            let uniqueId = uuidv4();
            //this.$store.commit("setNotificationReplie",{'uuid':uniqueId,'notification_id':-1,'direction':'outgoing','value':this.values, 'parent_id':this.notification.notification_id, 'type':this.notification.notification_type, 'sent':false})
            let parentId;
            if(this.notification.notification_type !== 'reply'){
                parentId = this.notification.notification_id;
            }else{
                parentId = this.notification.parent_id;
            }
            this.$store.dispatch('sendNotificationReply', {'uuid':uniqueId,'notification_id':this.notification.notification_id,'direction':'outgoing','value':this.values, 'parent_id':parentId, 'type':this.notification.notification_type, 'title':this.notification.notification_title});
            
            this.answering = false;
        },
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
    },
    mixins:[dateFunctions],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";

.notification-header{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    text-decoration: none;
    color: $fontColorDark;
}
.title{
    font-weight: bold;
    padding-bottom: 3px;
    width: 100%;
    display: block;
}
.notification-time{
    flex-basis: 60%;
}
.id{
    flex-basis: 40%;
    text-align: right;
}
.text{
    width: 100%;
    display: block;
}
.dialogue-wrapper{
    position: relative;
    width:100%;
}
.dialogue-text{
    padding: 8px;
    width:80%;
    float:left;
}
.answer-wrapper{
    margin-left: auto;
}
.outgoing{
    text-align: right;
    float:right;
}
.answer-button, .send-button{
    width: 100px;
    text-align: center;
    float:right;
    cursor: pointer;
    border-radius: 5px;
    padding: 8px;
    color: $fontColorLight;
    background-color: $bgColorDark;
}
.answer-textbox{
    margin: 8px 0;
    width: 100%;
    resize: vertical;
}

.dismiss-notification-button{
    width: 24px;
    height: 24px;
    background-color: $bgColorLight;
    border-radius: 15px;
    border: 1px solid black;
    position: absolute;
    top: -18px;
    right: -12px;
    padding: 2px 4px;
    cursor: pointer;
}
.dismissIcon{
    width: 10px;
    height: 19px;
    display: block;
    margin: auto;
    padding: 5px 0;
}
.sentIcon{
    width: 10px;
    margin: 3px;
}
.noReplies{
    width: 100%;
    float: left;
    text-align: center;
    font-weight: bold;
    margin: 15px 0;
}
.collapseIconWrap{
    position: absolute;
    bottom: 8px;
    .collapseIcon{
        width:20px;
        transition-duration: 0.3s;
        transition-property: transform;
        &.expanded{
            transform: rotate(180deg);
        }
    }
}
</style>