<template>
    <div class="footer-wrap">
        <p v-for='(message, index) in messages' :key='index' :class="['msg-wrap',message.values.type]">
            {{message.values.text}}
        </p>
    </div>
</template>

<script>

export default {
    computed:{
        messages:function(){
            return this.$store.state.status.footerMsg.msg;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.footer-wrap{
    position: fixed;
    bottom: 0;
    height: auto;
    width: 100%;
    z-index: 1001;
    .msg-wrap{
        margin:0;
        padding: 12px 5px;
        background-color: $bgColorMedLessTransparent;
        &.neutral{

        }
        &.success{
            color:$trafficLightColorGreen;
        }
        &.warning{
            color:$trafficLightColorYellow;
        }
        &.error{
            color:$trafficLightColorRed;
        }
    }
}
</style>